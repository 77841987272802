import axios from "axios";

var apiUrl = JSON.parse(localStorage.getItem('apiServer'));
if (apiUrl === null || apiUrl === 'https://corsproxy.io/?https://shows.cf') {
    apiUrl = 'https://corsproxy.io/?url=https://jfper.link';
}

const instance = axios.create({
    baseURL: apiUrl
});

export default instance;